
.warscroll {
  background: antiquewhite;
  border-radius: 10px;
  margin: 5px;
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  flex-direction: column;
}

.top {
  display: flex;
  margin: 5px;
  align-items: center;
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.controlGroup {

}

.mathHammer {
  display: flex;
  flex-direction: row;
  justify-content: center;

  div {
    margin-right: 15px;
  }
}

.weaponName {
  font-weight: bold;
}

.weaponAbility {
  font-weight: lighter;
  font-size: 14px;
  margin-right: 5px;
}

.weapons {
  padding: 5px;
}

.wargear {
  width: 30%;
  margin: 5px;
}

.select {
  display: flex;
  flex-direction: row;
  width: 75%;
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.selectGroup {
  display: flex;
  flex-direction: column;
  margin: 0.5vw;
  width: 50%;

  .banner {
    height: 2.5vw;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.heading{
  width: 80%;
  align-items: center;
  text-align: center;
}

.noPrint{
  @media print {
    display: none !important;
  }
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left {
  display: flex;
  flex-direction: column;
  background: #282c34;
  color: aliceblue;
  margin: 5px;
  padding: 10px;
  text-align: left;
  border-radius: 10px;
  text-transform: uppercase;
  width: 10vw;
  min-width: 10vw;
  height: 100%;
}

.main {
  margin: 5px;
  padding: 10px;
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    .ability {
      width: 100%;
    }
    .wargear {

      width: 95%;
    }
  }
}

.right {
  width: 100%;
}

.abilities {
  display: flex;
  flex-wrap: wrap;
  margin: 5px;

  .ability {
    width: 30%;
  }

  @media only screen and (max-width: 768px) {
    .ability {
      width: 100%;
    }
  }
}

.justAbilities {
  width: 90%;
}

.img {
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.abilityList {
  margin: 15px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .ability {
    width: 30%;
  }

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    .ability {
      width: 90%;
    }
  }
}

.selectTop {
  flex-grow: 1;
}

.abilityBanner {
  margin: 5px;
  padding: 5px;
  display: flex;
  color: aliceblue;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  height: 20%;

  h1 {
    margin: 0;
  }
}

.heroPhase {
  background: #b2a04f;
}

.movementPhase {
  background: #8e8e8e;
}

.defensive {
  background: #3d5218;
}

.combatPhase {
  background: #882024;
}

.startOfTurn {
  background: #000000;

}

.endOfTurn {
  background: #6d4882;
}

.chargePhase {
  background: #bf7731;
}

.shootingPhase {
  background: #225c6f;
}

.mostEffective {
  background: red;
  color: #282c34;

}

.effective {
  background: orange;
  color: #282c34;

}

.fine {
  background: yellow;
  color: #282c34;
}

.ineffective {
  background: yellowgreen;
  color: #282c34;

}

.leastEffective {
  background: green;
  color: #282c34;

}

.tierTitle {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  min-width: 310px;
}

.controls {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.banner {
  margin: 5px;
  display: flex;
  background: #282c34;
  color: aliceblue;
  border-radius: 10px;
  justify-content: center;
  text-transform: uppercase;
  height: 100%;

  h1 {
    margin: 0 auto 0 auto;
  }
}

.table {
  text-align: center;
  border: 0 solid;
  border-spacing: 0;
  table-layout: auto;
  width: 100%;
  margin-bottom: 5px;

  caption {
    background: #282c34;
    color: aliceblue;
    font-weight: bold;
    text-transform: uppercase;
  }

  thead {
    background: #2c346d;
    color: aliceblue;
  }

  tbody {
    background: #bbcad3;
    color: #042841;
  }

  @media only screen and (max-width: 768px) {
    width: 99%;
  }
}

.health {
  text-align: center;
  border: 0 solid;
  border-spacing: 0;
  table-layout: auto;
  width: 100%;
  margin-bottom: 5px;
  padding: 5px;

  caption {
    background: #2a3428;
    color: aliceblue;
    font-weight: bold;
    text-transform: uppercase;
  }

  thead {
    background: #2a3428;
    color: aliceblue;
  }

  tbody {
    background: #b5d9a6;
    color: #042841;
  }

  @media only screen and (max-width: 768px) {
    width: 99%;
  }
}

.listContainer {
  button {
    margin-bottom: 10px;
  }
}

.list {
  display: flex;
  flex-direction: column;

  button {
    margin: 10px;
  }
}

.listEntry {
}

.keywordBanner {
  display: flex;
  flex-direction: row;

  .words {
    font-weight: bold;
  }

}

.keywords {
  background: white;
  display: flex;
  flex-direction: row;
  border: 1px #2c346d solid;
  padding: 5px;

  .words {
    font-weight: bold;
  }

  strong {
    padding: 5px;
    background: #2c346d;
    color: white;
  }
}

.Root {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px;
}

.Column {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 5px;
  padding: 20px;
  min-height: 100px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.Item {
  appearance: none;
  background: #FFF;
  color: #666;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: grab;
}
